import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * AUTH SYSTEM EXPLANATION: *
 * - All route childs but 'login' have a canActivate and canActivateChild guard
 *   that ckecks if the user is authenticated through this.authService.getToken().
 *
 * - The user's authetication is checked in every route change inside the 'admin' state.
 *   If is not authenticated, the user is redirected to the 'login' state.
 *
 * - The auth session process works like this:
 *    - The user log in with Email and Password.
 *    - The data is sent to the server.
 *    - The server responds with the user token and roles.
 *    - The token is setted in the app (localStorage/sessionStorage).
 *    - The auth.interceptor attaches the auth token to the headers of every
 *      communication with the server.
 */

@Injectable()
export class AuthService {
  constructor(private _httpClient: HttpClient) {}

  getToken() {
    return sessionStorage.getItem('ess_token') || localStorage.getItem('ess_token');
  }

  setToken(token, keepMeLoggedIn?) {
    const storage = keepMeLoggedIn ? localStorage : sessionStorage;
    // Remove previous/other user's tokens
    this.deleteToken();
    storage.setItem('ess_token', token);
  }

  deleteToken() {
    localStorage.removeItem('ess_token');
    sessionStorage.removeItem('ess_token');
  }

  // Fake signin required by the old payments implementation
  fakeSignIn() {
    const fakeuser = {
      email: 'newsletter@essentialist.com',
      password:
        'fF+DGcwc&-236An9S&=?9k-tJZm3kA6&mu$F=@yf$#3-9HfzdDvJQ4P76PzMwxyUsRzus@?tDAS$yw*KRXu9=G*BBxcxh^7weW2KXv$&xDFu*AKEWxmb-rF5Ts8#$UzhgWB@yQ@npTyN2Hg%ScQxt$!7y9cYSvu9Xwv#6^9Wq6UGNzx+x5@X3jY@!&C3A^3W2Mg?+PxPb#*M*aK=9HjK=gtVFW#fuGSQ=Zq_j9&ggZy9g!uCufjEZB9Dk2q-*GB_',
    };

    const url = `${environment.apiEnv}api/rest-auth/login/`;

    return this._httpClient.post(url, fakeuser);
  }
}
